<template>
  <v-layout>
    <v-col class="custom-content-container pt-0" cols="12" :md="12" :sm="12">
      <v-layout class="d-block customer-create-new-customer">
        <v-row>
          <v-col
            class="iv-custom-field"
            cols="3"
            md="3"
            sm="3"
            v-on:mouseleave="imageTemplate = false"
            v-on:mouseover="imageTemplate = true"
          >
            <template>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="getProfileImage"
                max-height="200"
                max-width="200"
                height="200px"
                width="200px"
                class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
              >
              </v-img>
              <div class="margin-auto">
                <v-btn
                  class="my-4 custom-bold-button width-100"
                  outlined
                  small
                  v-on:click="selectImage"
                  color="cyan"
                  :loading="imageLoading"
                  :disabled="imageLoading"
                >
                  <template v-if="getProfileImage">Change Image</template>
                  <template v-else>Upload Image</template>
                </v-btn>
                <div class="d-none">
                  <v-file-input
                    ref="uploadImage"
                    v-on:change="updateProfileImage"
                    :rules="imageRules"
                    accept="image/png, image/jpeg, image/jpg"
                  ></v-file-input>
                </div>
              </div>
            </template>
          </v-col>
          <v-col class="iv-custom-field" cols="9" md="9" sm="9">
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid>
                <v-row v-if="lodash.isEmpty(customer)">
                  <v-col class="pt-0" md="3" sm="12">
                    <label class="font-size-16 font-weight-500" for="salutation"
                      >Salutation</label
                    >
                    <v-select
                      id="salutation"
                      v-model.trim="detail.salutation"
                      :items="salutationList"
                      dense
                      filled
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      label="Salutation"
                      solo
                      flat
                      color="cyan"
                    ></v-select>
                  </v-col>
                  <v-col class="pt-0" md="5" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="first_name"
                      >First Name</label
                    >
                    <v-text-field
                      id="first_name"
                      v-model.trim="detail.first_name"
                      :rules="[
                        validateRules.required(detail.first_name, 'first name'),
                        validateRules.minLength(
                          detail.first_name,
                          'first name',
                          2
                        ),
                        validateRules.maxLength(
                          detail.first_name,
                          'first name',
                          100
                        ),
                      ]"
                      dense
                      filled
                      label="First Name"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                      v-on:keyup="appendDisplayName()"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-0" md="4" sm="12">
                    <label class="font-size-16 font-weight-500" for="last_name"
                      >Last Name</label
                    >
                    <v-text-field
                      id="last_name"
                      v-model.trim="detail.last_name"
                      :rules="[
                        validateRules.minLength(
                          detail.last_name,
                          'last name',
                          2
                        ),
                        validateRules.maxLength(
                          detail.last_name,
                          'last name',
                          100
                        ),
                      ]"
                      dense
                      filled
                      label="Last Name"
                      solo
                      flat
                      color="cyan"
                      v-on:keyup="appendDisplayName()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col md="12" sm="12">
                    <v-switch
                      v-model.trim="detail.primary"
                      class="mt-0"
                      inset
                      color="cyan"
                      label="Use company name as the display name"
                    ></v-switch>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col md="6" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="company_name"
                      :class="{ required: detail.primary }"
                      >Company Name</label
                    >
                    <v-text-field
                      id="company_name"
                      v-model.trim="detail.company_name"
                      :rules="company_rules()"
                      dense
                      filled
                      label="Company Name"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" class="d-none">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="display_name"
                      >Display Name</label
                    >
                    <v-text-field
                      id="display_name"
                      v-model.trim="detail.display_name"
                      :rules="[
                        validateRules.required(
                          detail.display_name,
                          'display name'
                        ),
                        validateRules.minLength(
                          detail.display_name,
                          'display name',
                          2
                        ),
                        validateRules.maxLength(
                          detail.display_name,
                          'display name',
                          100
                        ),
                      ]"
                      dense
                      filled
                      label="Display Name"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col md="6" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="entity-id"
                      >Entity</label
                    >
                    <v-select
                      id="entity-id"
                      v-model.trim="detail.entity_id"
                      :items="entityList"
                      dense
                      filled
                      :rules="[
                        validateRules.required(detail.entity_id, 'entity'),
                      ]"
                      item-text="name"
                      item-value="id"
                      item-color="cyan"
                      label="Entity"
                      solo
                      flat
                      color="cyan"
                    ></v-select>
                  </v-col> -->
                  <!-- <v-col md="6" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="group_id"
                      >Group</label
                    >
                    <v-select
                      id="group_id"
                      v-model.trim="detail.group_id"
                      :items="groupList"
                      dense
                      filled
                      :rules="[
                        validateRules.required(detail.group_id, 'group'),
                      ]"
                      item-text="text"
                      item-value="id"
                      item-color="cyan"
                      label="Group"
                      solo
                      flat
                      color="cyan"
                    ></v-select>
                  </v-col> -->
                  <v-col v-if="false" md="4" sm="12">
                    <PhoneTextField
                      id="t-phone"
                      v-model="detail.phone"
                      :value="detail.phone"
                      required
                    >
                    </PhoneTextField>
                  </v-col>
                  <!-- <v-col md="6" sm="12">
                    <label class="font-size-16 font-weight-500" for="website"
                      >Website</label
                    >
                    <v-text-field
                      id="website"
                      v-model.trim="detail.website"
                      :rules="[
                        validateRules.minLength(detail.website, 'website', 3),
                        validateRules.maxLength(detail.website, 'website', 50),
                      ]"
                      dense
                      filled
                      label="Website"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                    ></v-text-field>
                  </v-col> -->
                  <v-col md="6" sm="12" v-if="false">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="security_code"
                      >Security Code</label
                    >
                    <v-text-field
                      id="security_code"
                      v-model.trim="detail.security_code"
                      :rules="[
                        validateRules.required(
                          detail.security_code,
                          'security code'
                        ),
                        validateRules.minLength(
                          detail.security_code,
                          'security code',
                          3
                        ),
                        validateRules.maxLength(
                          detail.security_code,
                          'security code',
                          50
                        ),
                      ]"
                      dense
                      filled
                      label="Security Code"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                    ></v-text-field>
                  </v-col>

                  <v-col md="6" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="company_name"
                      >UEN</label
                    >
                    <v-text-field
                      id="uen"
                      v-model.trim="detail.uen"
                      dense
                      filled
                      label="UEN"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="company_name"
                      >Quotation/Contract Reference</label
                    >
                    <v-text-field
                      id="quotation_contract_refrence"
                      v-model="detail.quotation_contract_refrence"
                      dense
                      filled
                      label="Quotation/Contract Reference"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="company_name"
                      >Service Details</label
                    >
                    <v-text-field
                      id="Service-Details"
                      v-model.trim="detail.service_detail"
                      dense
                      filled
                      label="Service Details"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="company_name"
                      >Service Frequency</label
                    >
                    <v-text-field
                      id="Service-Frequency"
                      v-model="detail.service_frequency"
                      dense
                      filled
                      label="Service Frequency"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <template v-if="false">
                    <v-col md="4" sm="12">
                      <v-hover v-slot="{ hover }">
                        <div class="custom-google-autocomplete">
                          <v-text-field
                            append-icon="search"
                            :loading="autocompleteLoading"
                            v-on:keyup="getGoogleAddress"
                            v-on:blur="isFocused = false"
                            v-on:focus="isFocused = true"
                            v-model.trim="detail.postal_code"
                            :rules="[
                              validateRules.required(
                                detail.postal_code,
                                'postal code'
                              ),
                              validateRules.minLength(
                                detail.postal_code,
                                'postal code',
                                6
                              ),
                              validateRules.maxLength(
                                detail.postal_code,
                                'postal code',
                                6
                              ),
                            ]"
                            dense
                            filled
                            label="Postal Code"
                            solo
                            flat
                            v-mask="'######'"
                            color="cyan"
                            class="required-field"
                          >
                          </v-text-field>
                          <v-list
                            v-if="
                              (hover || isFocused) && autocompleteList.length
                            "
                            two-line
                            elevation="4"
                            class="custom-google-autocomplete-list"
                          >
                            <v-list-item
                              v-for="(address, index) in autocompleteList"
                              :key="index"
                              link
                              v-on:click="selectGoogleAddress(index)"
                            >
                              <v-list-item-icon class="m-0 mr-3 my-auto">
                                <v-icon color="cyan"> mdi-map-marker </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content
                                class="py-1"
                                v-if="
                                  lodash.isEmpty(
                                    address.structured_formatting
                                  ) === false
                                "
                              >
                                <v-list-item-title class="font-size-14">{{
                                  address.structured_formatting.main_text
                                }}</v-list-item-title>
                                <v-list-item-subtitle class="font-size-12">{{
                                  address.structured_formatting.secondary_text
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-content class="py-1" v-else>
                                <v-list-item-title class="font-size-14">{{
                                  address.description
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </v-hover>
                    </v-col>
                    <v-col md="4" sm="12">
                      <v-text-field
                        v-model.trim="detail.unit_number"
                        :rules="[
                          validateRules.minLength(
                            detail.unit_number,
                            'unit no',
                            1
                          ),
                          validateRules.maxLength(
                            detail.unit_number,
                            'unit no',
                            100
                          ),
                        ]"
                        dense
                        filled
                        label="Unit No."
                        solo
                        flat
                        color="cyan"
                        class="required-field"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" sm="12">
                      <v-text-field
                        v-model.trim="detail.address_line_1"
                        :rules="[
                          validateRules.minLength(
                            detail.address_line_1,
                            'address line 1',
                            3
                          ),
                          validateRules.maxLength(
                            detail.address_line_1,
                            'address line 1',
                            50
                          ),
                        ]"
                        dense
                        filled
                        label="Address Line 1"
                        solo
                        flat
                        color="cyan"
                        class="required-field"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" sm="12">
                      <v-text-field
                        v-model.trim="detail.address_line_2"
                        :rules="[
                          validateRules.minLength(
                            detail.address_line_2,
                            'address line 2',
                            3
                          ),
                          validateRules.maxLength(
                            detail.address_line_2,
                            'address line 2',
                            50
                          ),
                        ]"
                        dense
                        filled
                        label="Address Line 2"
                        solo
                        flat
                        color="cyan"
                        class="required-field"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
                <template>
                  <v-row>
                    <v-col md="12" sm="12">
                      <h3>Contact Details</h3>
                    </v-col>
                  </v-row>
                  <template v-for="(cdata, index) in emailDetails">
                    <v-row class="contact-detail-row" :key="'email-' + index">
                      <v-col md="3">
                        <label
                          class="font-size-16 font-weight-500 required"
                          :for="`email-type-${index}`"
                          >Type</label
                        >
                        <v-select
                          :id="`email-type-${index}`"
                          v-model.trim="cdata.title"
                          :items="emailTypeList"
                          :rules="[validateRules.required(cdata.title, 'type')]"
                          dense
                          filled
                          item-text="text"
                          item-value="value"
                          item-color="cyan"
                          label="Type"
                          solo
                          flat
                          color="cyan"
                        ></v-select>
                      </v-col>
                      <v-col md="7">
                        <label
                          class="font-size-16 font-weight-500 required"
                          :for="`email-${index}`"
                          >Email</label
                        >
                        <v-text-field
                          :id="`email-${index}`"
                          v-model.trim="cdata.value"
                          :rules="[
                            validateRules.required(cdata.value, 'email'),
                            validateRules.validEmail(cdata.value, 'email'),
                            validateRules.minLength(cdata.value, 'email', 2),
                            validateRules.maxLength(cdata.value, 'email', 100),
                          ]"
                          dense
                          filled
                          label="Email"
                          solo
                          flat
                          color="cyan"
                          class="required-field"
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <template v-if="index > 0">
                          <v-btn
                            class="mx-2 mt-30"
                            color="red lighten-1"
                            dark
                            fab
                            small
                            v-on:click="removeEmailDetail(index, cdata)"
                          >
                            <v-icon dark> mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-btn
                            class="mx-2 mt-30"
                            color="cyan"
                            dark
                            fab
                            small
                            v-on:click="pushEmailDetail"
                          >
                            <v-icon dark> mdi-plus</v-icon>
                          </v-btn>
                        </template>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-for="(cdata, index) in contactDetails">
                    <v-row class="contact-detail-row" :key="'contact-' + index">
                      <v-col md="3">
                        <label
                          class="font-size-16 font-weight-500 required"
                          :for="`contact-type-${index}`"
                          >Type</label
                        >
                        <v-select
                          :id="`contact-type-${index}`"
                          v-model.trim="cdata.title"
                          :items="contactTypeList"
                          :rules="[validateRules.required(cdata.title, 'type')]"
                          dense
                          filled
                          item-text="text"
                          item-value="value"
                          item-color="cyan"
                          label="Type"
                          solo
                          flat
                          color="cyan"
                        ></v-select>
                      </v-col>
                      <v-col md="7">
                        <label
                          class="font-size-16 font-weight-500 required"
                          :for="`contact-${index}`"
                          >Phone Number</label
                        >
                        <PhoneTextField
                          :id="`contact-${index}`"
                          v-model="cdata.value"
                          :value="cdata.value"
                          required
                        >
                        </PhoneTextField>
                      </v-col>
                      <v-col md="2">
                        <template v-if="index > 0">
                          <v-btn
                            class="mx-2 mt-6"
                            color="red lighten-1"
                            dark
                            fab
                            small
                            v-on:click="removeContactDetail(index)"
                          >
                            <v-icon dark> mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-btn
                            class="mx-2 mt-6"
                            color="cyan"
                            dark
                            fab
                            small
                            v-on:click="pushContactDetail"
                          >
                            <v-icon dark> mdi-plus</v-icon>
                          </v-btn>
                        </template>
                      </v-col>
                    </v-row>
                  </template>
                </template>
              </v-container>
            </perfect-scrollbar>
          </v-col>
        </v-row>
      </v-layout>
    </v-col>
    <template v-if="false && !isDialog">
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </template>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import { GET, QUERY, DELETE } from "@/core/services/store/request.module";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { getConfig } from "@/core/services/local.service";

import { CustomerEventBus } from "@/core/lib/customer.lib";

export default {
  name: "contact-details",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin, CustomerMixin, FileManagerMixin],
  props: {
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    lead: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupList: [],
      entityList: [],
      proposal: 0,
      timeoutLimit: 500,
      timeout: null,
      autocompleteList: new Array(),
      autocompleteLoading: false,
      isFocused: false,
      detail: {
        primary: false,
        salutation: "mr",
        first_name: null,
        last_name: null,
        company_name: null,
        uen: null,
        service_detail: null,
        service_frequency: null,
        display_name: null,
        profile_logo: null,
        entity_id: null,
        group_id: 1,
        phone: null,
        website: null,
        security_code: null,
        quotation_contract_refrence: null,
        unit_number: null,
        address_line_1: null,
        address_line_2: null,
        postal_code: null,
        country: null,
        state: null,
        city: null,
        details: [],
      },
      imageLoading: false,
      emailDetails: [
        {
          title: "main",
          value: null,
          type: 1,
          id: null,
        },
      ],
      contactDetails: [
        {
          title: "main",
          value: null,
          type: 2,
          id: null,
        },
      ],
      imageTemplate: false,
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    "detail.primary"() {
      if (this.detail.primary) {
        this.detail.display_name = this.detail.company_name;
      }
    },
    "detail.company_name"() {
      if (this.detail.primary) {
        this.detail.display_name = this.detail.company_name;
      }
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("saveContactDetail", param);
      },
    },
    lead: {
      deep: true,
      immediate: true,
      handler() {
        this.getLead();
      },
    },
    emailDetails: {
      deep: true,
      handler() {
        this.updateDetails();
      },
    },
    contactDetails: {
      deep: true,
      handler() {
        this.updateDetails();
      },
    },
  },
  methods: {
    emit_details() {
      CustomerEventBus.$emit("update:default-contact-person", true);
    },
    company_rules() {
      const rules = [];

      if (this.detail.primary) {
        rules.push(
          this.validateRules.required(this.detail.display_name, "display name")
        );
      }

      rules.push(
        this.validateRules.minLength(
          this.detail.display_name,
          "display name",
          2
        )
      );

      rules.push(
        this.validateRules.maxLength(
          this.detail.display_name,
          "display name",
          100
        )
      );

      return rules;
    },
    appendDisplayName() {
      const result = [];
      const { first_name, last_name } = this.detail;
      if (first_name) {
        result.push(first_name);
      }
      if (last_name) {
        result.push(last_name);
      }
      this.detail.display_name = result.join(" ");
    },
    clearAddress() {
      const _this = this;
      _this.detail.postal_code = null;
      _this.detail.address_line_1 = null;
      _this.detail.address_line_2 = null;
      _this.detail.unit_number = null;
    },
    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.detail.postal_code = result.zip_code;
              _this.detail.address_line_1 = result.street_1;
              _this.detail.address_line_2 = result.street_2;
              _this.detail.unit_number = result.unit_no;
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.detail.postal_code && _this.detail.postal_code.length == 6) {
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.detail.postal_code },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        } else {
          _this.autocompleteList = new Array();
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateDetails() {
      const _this = this;
      _this.detail.details = [];
      for (let i = 0; i < _this.emailDetails.length; i++) {
        if (_this.emailDetails[i]) {
          delete _this.emailDetails[i].default;
          _this.detail.details.push(_this.emailDetails[i]);
        }
      }
      for (let z = 0; z < _this.contactDetails.length; z++) {
        if (_this.emailDetails[z]) {
          delete _this.emailDetails[z].default;
          _this.detail.details.push(_this.contactDetails[z]);
        }
      }
      this.emit_details();
    },
    pushEmailDetail() {
      this.emailDetails.push({
        title: "main",
        value: null,
        type: 1,
        id: null,
      });
    },
    removeEmailDetail(index, row) {
      if (row && row.id) {
        this.$store
          .dispatch(DELETE, {
            url: `customer/${row.customer}/detail`,
            data: { details: [row.id] },
          })
          .then(() => {
            this.emailDetails.splice(index, 1);
          })
          .catch((error) => {
            this.logError(error);
          });
      } else {
        this.emailDetails.splice(index, 1);
      }
    },
    pushContactDetail() {
      this.contactDetails.push({
        title: "main",
        value: null,
        type: 2,
        id: null,
      });
    },
    removeContactDetail(index) {
      this.contactDetails.splice(index, 1);
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.detail.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    getGroupList() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "customer/group-list",
        })
        .then(({ data }) => {
          _this.groupList = data.group_list;
          if (_this.lodash.isEmpty(_this.customer) === true) {
            _this.detail.security_code = data.security_code;
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    editDetails() {
      const _this = this;
      _this.defaultPerson = _this.getDefaultPerson(_this.customer);
      _this.emailDetails = _this.getEmailDetails(_this.customer);
      _this.contactDetails = _this.getPhoneDetails(_this.customer);

      _this.detail.salutation = _this.defaultPerson.title;
      _this.detail.first_name = _this.defaultPerson.first_name;
      _this.detail.last_name = _this.defaultPerson.last_name;

      _this.detail.primary = _this.customer.primary;

      _this.detail.company_name = _this.customer.company_name;
      _this.detail.uen = _this.customer.uen;
      _this.detail.service_detail = _this.customer.service_detail;
      _this.detail.service_frequency = _this.customer.service_frequency;
      _this.detail.display_name = _this.customer.display_name;
      _this.detail.entity_id = _this.customer.entity_id;
      _this.detail.group_id = _this.customer.group_id;
      _this.detail.phone = _this.customer.phone;
      _this.detail.website = _this.customer.website;
      _this.detail.unit_number = _this.customer.unit_number;
      _this.detail.address_line_1 = _this.customer.address_line_1;
      _this.detail.address_line_2 = _this.customer.address_line_2;
      _this.detail.postal_code = _this.customer.postal_code;
      _this.detail.country = _this.customer.country;
      _this.detail.security_code = _this.customer.security_code;
      _this.detail.quotation_contract_refrence =
        _this.customer.quotation_contract_refrence;
      _this.detail.state = _this.customer.state;
      _this.detail.city = _this.customer.city;
      _this.detail.profile_logo = _this.customer.profile_logo;
    },
    getLead() {
      const _this = this;
      let fullname = _this.lead.name ? _this.lead.name.split(" ") : [];
      _this.detail.first_name = fullname[0];
      _this.detail.last_name = fullname[1];
      _this.detail.company_name = _this.lead.company;
      _this.detail.uen = _this.lead.uen;

      _this.detail.display_name = _this.lead.company;
      _this.detail.phone = _this.lead.phone_number;
      _this.detail.website = _this.lead.website;
      _this.detail.quotation_contract_refrence =
        _this.lead.quotation_contract_refrence;
      _this.detail.postal_code = _this.lead.zip;
      _this.detail.country = _this.lead.country;
      _this.detail.state = _this.lead.state;
      _this.detail.city = _this.lead.city;
      _this.detail.unit_number = _this.lead.unit_no;
      _this.detail.address_line_1 = _this.lead.address_1;
      _this.detail.address_line_2 = _this.lead.address_2;

      _this.emailDetails[0] = new Object({
        title: "main",
        value: _this.lead.email,
        type: 1,
        id: null,
      });

      _this.contactDetails[0] = new Object({
        title: "main",
        value: _this.lead.phone_number,
        type: 2,
        id: null,
      });

      _this.updateDetails();
    },
    getProposal() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "proposals/" + _this.proposal,
        })
        .then(({ data }) => {
          let fullname = data.name ? data.name.split(" ") : [];
          _this.detail.first_name = fullname[0];
          _this.detail.last_name = fullname[1];
          _this.detail.company_name = data.company;
          _this.detail.display_name = data.company;
          _this.detail.uen = data.uen;
          _this.detail.service_detail = data.service_detail;
          _this.detail.service_frequency = data.service_frequency;
          _this.detail.phone = data.phone_number;
          _this.detail.website = data.website;
          _this.detail.quotation_contract_refrence =
            data.quotation_contract_refrence;
          _this.detail.postal_code = data.zip;
          _this.detail.country = data.country;
          _this.detail.state = data.state;
          _this.detail.city = data.city;
          _this.detail.address_line_1 = data.address;

          _this.emailDetails[0] = new Object({
            title: "main",
            value: data.email,
            type: 1,
            id: null,
          });

          _this.contactDetails[0] = new Object({
            title: "main",
            value: data.phone_number,
            type: 2,
            id: null,
          });

          _this.updateDetails();
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.entityList = getConfig("entity");

    const firstEntity = _this.lodash.find(_this.entityList, { id: 1 });
    if (firstEntity) {
      _this.detail.entity_id = firstEntity.id;
    }

    _this.getGroupList();
    if (_this.lodash.isEmpty(_this.customer) === false) {
      _this.editDetails();
    }

    _this.proposal = _this.lodash.toSafeInteger(_this.$route.query.proposal);
    if (_this.proposal > 0) {
      _this.getProposal();
    }
  },
  components: {
    PhoneTextField,
  },
  computed: {
    getProfileImage() {
      return this.lodash.isEmpty(this.detail.profile_logo) === false
        ? this.detail.profile_logo.file.url
        : null;
    },
  },
};
</script>
